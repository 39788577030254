.Container {
  @media (min-width: 445px) and (max-width: 473px) {
    column-gap: 10px;
  }

  @media (min-width: 520px) and (max-width: 640px) {
    column-gap: 60px;
  }
}
.item {

    @media (min-width: 423px) and (max-width: 499.9px) {
    margin-right: 11px;
    margin-left: 11px
  }

    @media (min-width: 500px) and (max-width: 519.9px) {
    margin-right: 20px;
    margin-left: 11px
  }

   @media (min-width: 604px) and (max-width: 789px) {
    margin-right: 10px;
    margin-left: 10px
  }
}
