.tabContainer {
  @apply flex items-center w-max overflow-x-auto mx-auto md:mb-24 md:px-24;
}

.tabWrapper {
  @apply bg-display-100 h-56 w-[156px] md:h-[72px] first:pl-8 last:pr-8 flex justify-center items-center first:rounded-l-[40px] last:rounded-r-[40px];
}

.tabButtonWrapper {
  @apply px-8;
}

.tabButton {
  @apply p-8 rounded-[40px] h-40 md:p-16 md:h-[56px] flex justify-center items-center  w-[156px] cursor-pointer;

  & > h3 {
    @apply whitespace-nowrap h-40 md:text-lg font-semibold text-display-600 text-base;
  }
}

.tabButtonActive {
  composes: tabButton;

  @apply bg-display-0;

  & > h3 {
    @apply text-stone-500;
  }
}

.sliderContainer {
  @apply max-w-[1280px] w-full;

  overflow: initial !important;
}
