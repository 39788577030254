.gradientBorder {
  position: relative;
  border-radius: 40px;
  z-index: 0;
  @media (max-width: 1025px) and (min-width: 770px) {
    border-radius: 24px;
  }

  @media (max-width: 770px) {
    border-radius: 16px;
  }
}

.gradientBorder::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: inherit;
  padding: 2px;
  background: linear-gradient(
    90deg,
    #d9d9d9 0%,
    #737373 50%,
    #ffffff 75%,
    #374b2c 100%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  z-index: 10;
  pointer-events: none;
}

.cardContent {
  position: relative;
  border-radius: 40px;
  background: rgba(255, 255, 255, 0.1);
  height: 100%;
  @media (max-width: 1025px) and (min-width: 770px) {
    border-radius: 24px;
  }

  @media (max-width: 770px) {
    border-radius: 16px;
  }
}
