.gradientBorderTag {
  position: relative;
  border-radius: 24px;
  z-index: 0;
}

.gradientBorderTag::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: inherit;
  padding: 1px;
  background: linear-gradient(
    180deg,
    rgba(217, 217, 217, 0.8) 0%,
    rgba(115, 115, 115, 0.8) 50%,
    rgba(255, 255, 255, 0.8) 75%,
    rgba(55, 75, 44, 0.8) 100%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  z-index: 10;
}

.cardContent {
  position: relative;
  border-radius: 24px;
  background: rgba(255, 255, 255, 0.1);
  z-index: 1;
  height: 100%;
}

.colorGradient {
  background: linear-gradient(
    0deg,
    rgba(188, 188, 188, 0.8) 0%,
    rgba(255, 255, 255, 0.8) 100%
  );
}
