.animateShake {
  animation: shake 5s infinite;
}

@keyframes shake {
  0% {
    transform: translate(0);
  }

  80% {
    transform: translateY(4px) rotate(0deg);
  }
  82% {
    transform: translateY(-4px) rotate(0deg);
  }
  84% {
    transform: translateY(4px) rotate(0deg);
  }
  86% {
    transform: translateY(-4px) rotate(0deg);
  }
  88% {
    transform: translateY(0px) rotate(0deg);
  }
  90% {
    transform: translateY(-4px) rotate(0deg);
  }
  92% {
    transform: translateY(4px) rotate(0deg);
  }
  94% {
    transform: translateY(-4px) rotate(0deg);
  }
  96% {
    transform: translateY(4px) rotate(0deg);
  }
  98% {
    transform: translateY(-4px) rotate(0deg);
  }
  100% {
    transform: translateY(0px) rotate(0deg);
  }
}
