.Gradient {
  background: linear-gradient(0deg, #01261f 0%, rgba(1, 38, 31, 0.5) 50%);
}

.ghost {
  @apply font-sharon flex justify-center items-center cursor-pointer font-medium whitespace-nowrap;
  border-radius: 32px;
  text-decoration: none;
  background-color: red;
  @apply bg-transparent text-display-0 border-solid border-2 border-display-0;
  &:hover {
    background-color: rgba(255, 255, 255, 1);
    color: black;
  }
  padding: 13px 24px;
  &:active,
  &:focus,
  &:focus-visible,
  &:focus-within {
    border-color: rgba(249, 250, 251, 1);
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 0 8px 4px #fff;
    color: black;
  }
}
